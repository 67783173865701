.header_carousel_container {
	position: relative;
	/* z-index: 2000; */
}
.header_.whitebg {
	background-color: white;
}
.header_ {
	z-index: 2000;
	background-color: transparent;
	position: fixed;
	display: flex;
	transition: 0.3s all;
	justify-content: space-between;
	padding: 2% 5%;
	top: 0;
	left: 0;
	right: 0;
}
/* .header_ img {
	width: 75px;
	height: 30px;
	object-fit: contain;
} */
.lang_div {
	display: flex;
}
.lang_div span:first-child {
	color: #ffffff;
	cursor: pointer;
	font-size: 14px;
	transition: 0.3s all;
}
.lang_div span:last-child {
	cursor: pointer;
	font-size: 14px;
	transition: 0.3s all;
}
.carousel_img {
	width: 100%;
	height: 700px;
	object-fit: cover;
	animation: shrink 5s infinite alternate;
}
.carousel-indicators {
	right: 0 !important;
	left: auto !important;
	bottom: 5% !important;
}
.carousel-indicators li {
	width: 10px !important;
	height: 10px !important;
	border-top: 0 !important;
	border-bottom: 0 !important;
	border-radius: 3px !important;
	background-color: transparent !important;
	border: 1px solid #ffffff !important;
}
.carousel-indicators .active {
	background-color: white !important;
}
.text_on_carousel_p1 {
	font-size: 25px;
}
.text_on_carousel {
	position: absolute;
	display: flex;
	justify-content: center;
	top: 50%;
	left: 40%;
	transform: translate(-50%, -50%);
	max-width: 40%;
	/* max-height: 400px; */
	background-color: transparent;
	z-index: 1000;
	flex-direction: column;
	transition: 0.3s all;
}
.text_on_carousel h3 {
	font-size: 50px;
	text-transform: uppercase;
	font-weight: 1000;
}

.text_on_carousel._rightSide_text_on_carousel {
	position: absolute;
	right: 0;
	width: 40%;
	text-align: right;
	left: auto !important;
}
.text_on_carousel._rightSide_text_on_carousel p {
	font-family: 'urdu-regular';
}

.text_on_carousel._rightSide_text_on_carousel h3 {
	font-family: 'urdu-regular';
}
@keyframes shrink {
	0% {
		transform: scale(1.1, 1.1);
	}
	100% {
		transform: scale(1, 1);
	}
}

@media screen and (max-width: 1000px) {
	/* .text_on_carousel{
		left: 50%;
	} */
	.text_on_carousel h3 {
		font-size: 3.5vw;
	}
	.text_on_carousel_p2 {
		font-size: 2vw;
	}
	.text_on_carousel_p1 {
		font-size: 3vw;
	}
	.carousel_img {
		height: 60vh;
	}
	.text_on_carousel span img {
		width: 30%;
	}
	.text_on_carousel._rightSide_text_on_carousel {
		position: absolute;
		right: 0;
		text-align: right;
	}
}

@media screen and (max-width: 600px) {
	.text_on_carousel {
		max-width: 50%;
	}
	.header_ {
		padding: 4%;
	}
	.header_ img {
		width: 5%;
		height: auto;
		object-fit: contain;
	}
}
@media screen and (orientation: landscape) and (max-width: 1000px) {
	.carousel_img {
		height: 100%;
	}
}
