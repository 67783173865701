.dwb_container {
	width: 100%;
	padding: 3% 15%;
	display: flex;
	background-color: #f8f8f8;
	justify-content: space-around;
}
.dwb_text_btn {
	color: #04210e;
	display: flex;
	max-width: 350px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}
.dwb_text_btn h3 {
	font-size: 30px;
	font-weight: bold;
	margin-bottom: 5%;
}
.dwb_text_btn p {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 5%;
}
.dwb_text_btn button {
	background-color: #006400;
	color: #ffffff;
	font-size: 13px;
	cursor: pointer;
	font-family: 'roboto-light';
	transition: 0.3s all;
	padding: 2% 3%;
	border-radius: 6px;
	border: 0;
	height: 35px;
	width: 140px;
}
.dwb_text_btn button:hover {
	opacity: 0.7;
}
@media screen and (max-width: 1000px) {
	.dwb_text_btn button {
		width: 120px;
		height: 30px;
	}
	.dwb_container img {
		width: 50% !important;
		height: auto;
	}

	.dwb_container {
		flex-wrap: wrap;
	}
	.dwb_text_btn {
		margin-bottom: 4%;
	}
	.dwb_container img {
		width: 80%;
	}
	.dwb_text_btn h3 {
		font-size: 4vw;
	}
	.dwb_text_btn p {
		font-size: 3vw;
	}
}
