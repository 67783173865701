.slider_container {
  padding: 1% 0;
}

.car_carousel_heading {
  text-align: center;
  padding-top: 10%;
}
.car_carousel_heading h3 {
  color: #04210e;
  font-weight: bold;
  font-size: 30px;
  text-transform: uppercase;
}
.slick-prev {
  left: 1% !important;
  z-index: 10;
 
}

.slick-arrow.slick-prev{
  height: 100%;
  width: 100px;
  object-fit: contain;
}
.slick-arrow.slick-next{
  height: 100%;
  width: 100px;
  object-fit: contain;
}

.slick-next {
  right: 1% !important;
  z-index: 10;
}
/* .slick-center{
    transform: scale(1.05);
} */
.pagination_container {
  margin-top: 5%;
  display: flex;
  justify-content: center;
}
.pagination_container div {
  /* user-select: none; */
  cursor: pointer;
  max-width: 55px;
  height: 10%;
  margin: 0 2%;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.line {
  display: flex;
  height: 1px;
  border: 0;
  margin: 2px 0;
  padding: 0;
  border-top: 3px solid #006400;
}

.item_ {
  position: relative;
  width: 100% !important;
  height: 300px !important;
  display: flex !important;
  align-items: baseline;
  justify-content: center;
}
.slick-center .item_ .nam_nam_2 {
  height: 50%;
  width: 100%;
}

.nam_nam_2 {
  transition: 0.3s all;
  position: absolute;
  bottom: 0;
  width: 50%;
  z-index: 1000;
  height: 50%;
  object-fit: contain;
}
.nam_nam_1 {
  z-index: -1;
  width: 100%;
  height: 90%;
  animation: fade 2s linear;
  object-fit: contain;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@media screen and (max-width: 1000px) {
  .item_ {
    width: 100% !important;
    height: 300px !important;
  }
  .car_carousel_heading h3 {
    font-size: 4vw !important;
  }
  .pagination_container div {
    font-size: 2vw;
    margin: 0 3%;
  }
}
/* @media screen and (max-width: 680px) {
  .pagination_container div {
    margin: 0 1 !important;
  }
} */
