.footer_container {
  width: 100%;
}
.upper_portion {
  /* display: flex;
    flex */
  padding: 5% 12%;
  height: 80%;
  background: transparent linear-gradient(111deg, #001e00 0%, #006400 100%) 0%
    0% no-repeat padding-box;
}
.lower_portion {
  height: 20%;
  color: white;

  background-color: #222222;
}
.upper_portion h3 {
  font-weight: bold;
  font-size: 24px;
  color: white;
}
.upper_portion_icons {
  display: flex;
  align-items: center;
  margin-top: 2%;
}
.upper_portion_icons img {
  margin: 5px;
}
.hrefs_ {
  margin-top: 3%;
  display: flex;
  user-select: none;
  cursor: pointer;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 16px;
  line-height: 1;
  color: white;
}

.lower_portion div:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1% 0;
}
.lower_portion div:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
}
.lower_portion div:last-child p {
  font-family: "roboto-light" !important ;
  font-size: 10px;
}
.hrefs_.urduFonts div p {
  font-family: "urdu-regular";
}
@media screen and (max-width: 780px) {
  /* .footer_container{
        height: 650px;
    } */
  .hrefs_ div {
    margin: 5px 0;
    font-size: 12px;
  }
  .upper_portion_icons img {
    width: 40%;
    height: 50%;
    object-fit: contain;
  }
  .upper_portion h3 {
    font-size: 4vw;
  }
}
