@font-face {
	font-family: 'roboto-regular';
	src: url('./assets/fonts/Roboto-Regular.ttf');
}
@font-face {
	font-family: 'roboto-medium';
	src: url('./assets/fonts/Roboto-Medium.ttf');
}
@font-face {
	font-family: 'roboto-bold';
	src: url('./assets/fonts/Roboto-Bold.ttf');
}
@font-face {
	font-family: 'roboto-light';
	src: url('./assets/fonts/Roboto-Light.ttf');
}
@font-face {
	font-family: 'urdu-regular';
	src: url('./assets/fonts/urdu/urdu.ttf');
}
* {
  font-family: 'roboto-regular';
}

.blink_container {
	height: 100vh;
}
