.services_container {
	margin: 5%;
	padding: 2% 15%;
}
.desc_1 img {
	width: 100%;
	z-index: 1000;
}
.desc_1_text h3 {
	color: #04210e;
	font-size: 30px;

	font-weight: bold;
}
.desc_1_text {
	z-index: -1;
	padding: 2% 3%;
}
.desc_2 {
	margin-top: 10%;
}
.img_and_text_container {
	display: flex;
}
.img_ {
	width: 50%;
	z-index: 1000;
}

.img_ img {
	height: auto;
	width: 100%;
	height: auto;
	object-fit: cover;
}
.desc_2_text_ {
	display: flex;
	flex-direction: column;
	width: 50%;
	padding: 0 3%;
	justify-content: center;
	color: #04210e;
}
.desc_2_text_ h3 {
	font-size: 30px;
	font-weight: bold;
	margin-bottom: 3%;
}
.desc_2_text_ p {
	font-size: 14px;
}
@media screen and (max-width: 1000px) {
    .desc_2_text_ {
        width: 100%;
    }
    .img_{
        width: 100%;
    }
	.img_and_text_container {
		flex-wrap: wrap;
		margin-top: 5%;
	}
	.desc_2_text_ h3 {
		font-size: 4vw;
		margin-top: 5px;
	}
	.desc_2_text_ p {
		font-size: 3vw;
	}
	.desc_1_text h3 {
		font-size: 4vw;
	}
	.desc_1_text p {
		font-size: 3vw;
	}
}
