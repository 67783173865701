.PrivacyContainer {
  color: white;
  background: transparent linear-gradient(111deg, #001e00, #006400) 0 0
    no-repeat padding-box;

  margin: 0 auto;
  padding: 100px 0px 50px 0px;
}

.PrivacyContainer h3 {
  font-weight: bolder;
  font-size: xx-large;
}

.PrivacyContainer .date {
  /* color: #939393; */
}

.PrivacyContainer p {
  text-align: justify;
}

[data-custom-class='body'], [data-custom-class='body'] * {
  background: transparent !important;
  }
  [data-custom-class='title'], [data-custom-class='title'] * {
  font-family: Arial !important;
  font-size: 26px !important;
  color: #FFF !important;
  }
  [data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
  font-family: Arial !important;
  color: #FFF !important;
  font-size: 14px !important;
  }
  [data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
  font-family: Arial !important;
  font-size: 24px !important;
  color: #FFF !important;
  }
  [data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
  font-family: Arial !important;
  font-size: 17px !important;
  color: #FFF !important;
  }
  [data-custom-class='body_text'], [data-custom-class='body_text'] * {
  color: #FFF !important;
  font-size: 14px !important;
  font-family: Arial !important;
  }
  [data-custom-class='link'], [data-custom-class='link'] * {
  color: #3030F1 !important;
  font-size: 14px !important;
  font-family: Arial !important;
  word-break: break-word !important;
  }

  ul {
    list-style-type: square;
    }
    ul > li > ul {
    list-style-type: circle;
    }
    ul > li > ul > li > ul {
    list-style-type: square;
    }
    ol li {
    font-family: Arial ;
    }