.PrivacyContainer {
  color: white;
  background: transparent linear-gradient(111deg, #001e00, #006400) 0 0
    no-repeat padding-box;

  margin: 0 auto;
  padding: 100px 0px 50px 0px;
}

.PrivacyContainer h3 {
  font-weight: bolder;
  font-size: xx-large;
}

.PrivacyContainer .date {
  color: #939393;
}

.PrivacyContainer p {
  text-align: justify;
}
