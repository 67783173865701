.terms-container {
  padding-top: 100px;
  color: white;
  
  background: transparent linear-gradient(111deg, #001e00, #006400) 0 0;
    
}
.terms-container p {
  text-align: justify;
  text-justify: inter-word;
  font-size: 18px;
}
